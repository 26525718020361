<template>
  <div ref="container"
       class="relative py-40 md:py-64"
  >
    <div class="absolute inset-0 z-0 h-full overflow-hidden">
      <div class="absolute right-0 top-0 w-[75vw] h-[75vw] lg:w-[50vw] lg:h-[50vw] z-10 blur-[100px] md:blur-[200px]"
           :class="`bg-${backgroundGradient}`"
      />
    </div>
    <div class="absolute inset-0 z-0 h-full">
      <ThreeImageSequence v-if="container"
                          :trigger="progress"
                          class="sticky top-0 h-screen"
      />
    </div>

    <Container class="relative z-10 w-full py-5">
      <div ref="wrapperRef"
           :style="{
             height: wrapperHeight
           }"
      >
        <div ref="stickyRef"
             class="sticky z-20"
             :style="{
               top: `calc(50% - ${wrapperHeight} / 5)`
             }"
        >
          <h2 ref="leadRef"
              class="relative z-20 pt-32 text-5xl leading-tight text-gray-300 lg:text-8xl"
              v-html="refactored"
          />

          <div v-if="cta"
               class="relative z-20 flex mt-20"
          >
            <ButtonBase :label="cta.title"
                        :href="cta.href"
                        :type="cta.type"
            />
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import type { CtaType } from '../../types/button';
import { scroll } from 'motion';

interface KaraokeProps {
  backgroundGradient: string;
  copy: string;
  cta: CtaType;
}

const props = withDefaults(defineProps<KaraokeProps>(), {
  backgroundGradient: 'pink-purple',
  outfit7neo: false,
  color: 'purple-aqua'
});

const container = ref(null);
const wrapperRef = ref(null);
const leadRef = ref(null);
const stickyRef = ref(null);
const wrapperHeight = ref('100%');
const progress = ref(0);

const refactored = computed(() =>
  props.copy
    .replace(/\s\n/g, '\n')
    .replace(/\n\s/g, '\n')
    .replace(/./g, '<span class="text-gray-100 duration-500 text-opacity-20">$&</span>')
    .replace(/\n/g, '<br />')
);

onMounted(() => {
  const textHeight = leadRef.value.clientHeight;
  if (textHeight === 0) return;
  wrapperHeight.value = textHeight * 3 + 'px';
  window.removeEventListener('scroll', onScroll);
  window.addEventListener('scroll', onScroll, { passive: true });

  scroll(
    ({ y }) => {
      progress.value = y.progress;
    },
    {
      target: container.value,
      offset: ['start end', 'end start']
    }
  );
});

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll);
});

function onScroll(): void {
  if (window) {
    // On scrolling, past the middle of the screen, we start adding
    // classes to the children, based on the percentage of the scroll position
    const elementOffsetTop = wrapperRef.value.offsetTop;
    const wrapperRefHeight = wrapperRef.value.clientHeight;
    const elementScrollOffset = stickyRef.value.offsetTop;
    const childrenCount = leadRef.value.children.length;
    const scrollOffset = elementScrollOffset - elementOffsetTop;

    // Percentage can't be negative, so we clamp it to 0
    const percentage = Math.max(0, Math.min(1, scrollOffset / (wrapperRefHeight - scrollOffset))) * 100;

    // We color number of children, based on the percentage
    const numberToColor = Math.ceil((percentage / 100) * childrenCount);

    const children = leadRef.value.children;
    const textClass = 'text-opacity-20';

    for (let i = 0; i < children.length; i++) {
      const child = children[i];

      if (i < numberToColor && child.classList.contains(textClass)) {
        child.classList.remove(textClass);
      } else if (i >= numberToColor && !child.classList.contains(textClass)) {
        child.classList.add(textClass);
      }
    }
  }
}
</script>
